import CMLogo from "../assets/icons/appIcons/png/campminder.png"
import GSLogo from "../assets/icons/appIcons/png/googlesheet.png"
import MCLogo from "../assets/icons/appIcons/png/mailchimp.png"
import CCLogo from "../assets/icons/appIcons/constantcontact.svg"
import SBMLogo from "../assets/icons/appIcons/sbm.png"
import quickBookLogo from "../assets/icons/appIcons/quickbook.png"
import Info from "../assets/images/info.png"
import Aashish from "../assets/images/team/aashish.jpg"
import Carl from "../assets/images/team/carl-1.png"
import Dinhe from "../assets/images/team/dinhe-1.png"
import Eric from "../assets/images/team/eric-1.png"
import Henry from "../assets/images/team/henry-1.png"
import Moeez from "../assets/images/team/moeez-1.png"
import Dimple from "../assets/images/team/dimple-1.jpg"

export const data = {
  Integrations: {
    title: "Integration Features",
    subtitle: "Integrations to simplify every task so you can focus on the fun",
    items: [
      {
        id: 1,
        itemTitle: "Registration and Enrollment",
        itemDescription:
          "Automate data entry, reduce errors and send real-time updates by Integrating your camp registration software with other tools to ensure that data flows smoothly between them.",
        cases: {
          caseTitle: "Example use cases",
          caseItems: [
            {
              id: "1",
              logo: CMLogo,
              description: "Enrollment happens in Camp Minder"
            },
            {
              id: "2",
              logo: GSLogo,
              description: "Automatically send parents to Google Sheets"
            },
            {
              id: "3",
              logo: MCLogo,
              description: "Automatically send parents to Mail Chimp"
            },
            {
              id: "4",
              logo: CCLogo,
              description: "Automatically send parents to Constant Contact"
            }
          ],
          bgColor: "#EFF6FF"
        },
        blogLink: "https://blog.thecampstack.com/camp-software-integrations-and-automations/"
      },
      {
        id: 2,
        itemTitle: "Financial Management",
        itemDescription:
          "Track finances accurately by linking your camp management software with accounting platforms to help manage budgets, streamline billing, simplify reconciliations, and track payments without having to worry about double entries or discrepancies.",
        cases: {
          caseTitle: "Example use cases",
          caseItems: [
            {
              logo: CMLogo,
              description: "Sync CampMinder invoices"
            },
            {
              logo: quickBookLogo,
              description: "Simplify reporting and eliminate manual data entry"
            }
          ],
          bgColor: "#EFF6FF"
        },
        blogLink: "https://blog.thecampstack.com/camp-software-integrations-and-automations/"
      },
      {
        id: 3,
        itemTitle: "Transportation and Logistics",
        itemDescription:
          "Ensure that camper transportation is planned and coordinated in a smooth and efficient manner by integrating your camp management system with a transportation/logistics software to help manage bus routes, track arrivals and departures, and communicate schedules to parents.",
        cases: {
          caseTitle: "Example use cases",
          caseItems: [
            {
              logo: SBMLogo,
              description: "Routes update in School Bus Manager"
            },
            {
              logo: CMLogo,
              description: "Automatically send routes to Camp Minder"
            },
            {
              logo: GSLogo,
              description: "Automatically send routes to Google Sheets"
            }
          ],
          bgColor: "#EFF6FF"
        },
        blogLink: "https://blog.thecampstack.com/camp-software-integrations-and-automations/"
      },
      {
        id: 4,
        itemTitle: "Dashboard and Reporting",
        itemDescription:
          "Connect your camp registration software to reporting and dashboard tools to improve the way you view and use data. This helps you gather and display key metrics and generate reports automatically.",
        cases: {
          caseTitle: "Example use cases",
          caseItems: [
            {
              logo: CMLogo,
              description: "Every 15 mins, pull data from Camp Minder"
            },
            {
              logo: GSLogo,
              description: "Automatically send data to Google Sheets"
            }
          ],
          bgColor: "#EFF6FF"
        },
        blogLink: "https://blog.thecampstack.com/camp-software-integrations-and-automations/"
      },
      {
        id: 5,
        itemTitle: "Communication",
        itemDescription:
          "Integrating communication tools into your camp registration software can help  enable seamless communication between staff, campers and parents by sending updates, emergency alerts and messages automatically.",
        cases: {
          caseTitle: "Example use cases",
          caseItems: [
            {
              logo: CMLogo,
              description: "Sync enrollment updates from CampMinder"
            },
            {
              logo: CCLogo,
              description: "Trigger personalised emails to families"
            }
          ],
          bgColor: "#EFF6FF"
        },
        blogLink: "https://blog.thecampstack.com/camp-software-integrations-and-automations/"
      }
    ]
  },
  pricing: {
    title: "Pricing starts at",
    prices: [
      {
        id: 1,
        amount: "$150",
        description: "Per Month"
      },
      {
        id: 2,
        amount: "$750",
        description: "One Time Setup Fee"
      }
    ],
    listDescription: [
      {
        listIcon: Info,
        text: "Pre-pay for the year/season and get a discount for ",
        boldItem: "$125/mo",
        isFirst: false
      },
      {
        listIcon: Info,
        text: " for custom admin panels, widgets, and real time 2-way syncs.",
        boldItem: "Contact us",
        isFirst: true
      }
    ],
    button: {
      text: "Request a Demo",
      link: ""
    }
  },
  Team: {
    title: "Meet the Team Behind the Screen",
    subTitle: "Service is first at The Camp Stack. We’re here to help!",
    members: [
      {
        id: 1,
        image: Eric,
        name: "Eric Wittenberg",
        job: "Co-Founder, CEO"
      },
      {
        id: 2,
        image: Carl,
        name: "Carl Ngan",
        job: "Co-Founder, CTO"
      },
      {
        id: 3,
        image: Aashish,
        name: "Aashish Jangam",
        job: "Software Engineer"
      },
      {
        id: 4,
        image: Moeez,
        name: "Moeez Shahid",
        job: "Software Engineer"
      },
      {
        id: 6,
        image: Dimple,
        name: "May Dimple Almonia",
        job: "Executive Assistant"
      },
      {
        id: 7,
        image: Henry,
        name: "Henry Onyemerin Odili",
        job: "Marketing Specialist"
      },
      {
        id: 8,
        image: Dinhe,
        name: "Dinhe Calamateos",
        job: "Marketing Specialist"
      }
    ]
  },
  FAQ: {
    title: "Frequently Asked Questions",
    items: [
      {
        title: "What is The Camp Stack?",
        description:
          "The Camp Stack is an integration platform made specifically for camps. It is used to streamline camp operations by connecting key camp management systems like CampMinder, CampSIte, and UltraCamp to other essential tools like School Bus Manager for transportation logistics and SalesForce for marketing."
      },
      {
        title: "How can The Camp Stack be of benefit to my camp?",
        description:
          "The Camp Stack would help you run your camp more efficiently by automating data transfer between your camp software. This helps you do away with manual data entry and unnecessary errors while saving time."
      },
      {
        title: "Which systems can The Camp Stack integrate?",
        description:
          "We integrate your camp management systems with other tools that are crucial to the smooth operation of your camp. "
      },
      {
        title: "Can I have integrations based on my camp's unique needs?",
        description:
          "We understand that every camp is different, as is their software stack. That is why we offer customizable workflows to align with your camp operations. Whether it’s syncing camper data, managing staff schedules, or handling financial records, we tailor our integrations to suit you."
      },
      {
        title: "What kind of support can I expect?",
        description:
          "We provide ongoing support to ensure your integrations run smoothly. From troubleshooting to updates, our team is here to assist you every step of the way. We also offer training sessions to help your staff get the most out of The Camp Stack."
      },
      {
        title: "What makes The Camp Stack different from other integration platforms?",
        description:
          "The Camp Stack is built exclusively for summer camps. Unlike generic solutions, our platform understands the unique challenges camps face, offering targeted integrations that enhance your camp’s operations."
      }
    ]
  }
}
