import PropTypes from "prop-types"
import React from "react"
import checkIcon from "../../assets/icons/check_icon.svg"
import { Link } from "react-router-dom"
const PricingCard = ({ popularLabel, tag, price, features, cta, cardNo }) => {
  return (
    <div className={`pricing-card card-${cardNo + 1}`}>
      {popularLabel && <div className="popular-label">{popularLabel}</div>}
      <div className="tag">
        <p>{tag}</p>
      </div>
      <h1 className="price">{price}</h1>

      <hr />
      <div className="features">
        {features.map((feature, index) => (
          <div className="feature" key={index}>
            <img src={checkIcon} alt="icon" />
            <p>{feature}</p>
          </div>
        ))}
      </div>
      <Link to={"/contact"} className="request-cta">
        {cta}
      </Link>
    </div>
  )
}

export default PricingCard

PricingCard.propTypes = {
  popularLabel: PropTypes.string,
  tag: PropTypes.string,
  price: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  cta: PropTypes.string,
  cardNo: PropTypes.number.isRequired
}
