import React from "react"
import infoIcon from "../../assets/icons/info_icon.png"
import createWorkflowImage from "../../assets/images/createWorkflow.jpg"
import PricingCard from "./PricingCard"
import pricingSection from "./pricingSection.json"

export const PricingSection = () => {
  const { sectionTitle, cards, footer } = pricingSection

  return (
    <section className="section3" id="pricing">
      <div className="section-container">
        <h1 className="our-pricing-section-title">{sectionTitle}</h1>
        <div className="pricing-cards">
          {cards.map((card, index) => (
            <PricingCard
              key={index}
              cardNo={index}
              tag={card.tag}
              price={card.price}
              features={card.features}
              cta={card.cta}
              popularLabel={card.popularLabel}
            />
          ))}
        </div>
        <div className="pricing-section-footer pricing-section-footer-mobile">
          <img src={infoIcon} alt="infoIcon" />
          <p>{footer.info}</p>
        </div>
        <img src={createWorkflowImage} alt="Create Workflow" className="createWorkflow-image-new" />
        <div className="pricing-section-footer pricing-section-footer-desktop">
          <img src={infoIcon} alt="infoIcon" />
          <p>{footer.info}</p>
        </div>
      </div>
    </section>
  )
}
