import React from "react"
import Layout from "./../components/Common/Layout"
import { config } from "../config/index"
import { Link } from "react-router-dom"

const PrivacyPolicy = () => {
  const serviceSectionFromHomepage = () => (
    <Link
      to="/#apps"
      onClick={() =>
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          const section = document.getElementById("apps")
          if (section) {
            section.scrollIntoView({ behavior: "smooth" })
          }
        }, 300)
      }
    >
      Services
    </Link>
  )

  return (
    <Layout>
      <div className="textContainer">
        <div className="titleArea">
          <h2 className="title">Privacy Policy</h2>
        </div>
        <div className="texts">
          <p>
            Thank you for choosing to be part of our community at <Link to="/">thecampstack.com</Link> (“Company”, “we”,
            “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you
            have any questions or concerns about this privacy notice, or our practices with regards to your personal
            information, please contact us at <Link to="/contact">support@thecampstack.com</Link>.
          </p>
          <p>
            When you visit our website&nbsp;
            <Link to="/">https://thecampstack.com</Link>&nbsp;(the “Website”), and more generally, use any of our
            services (the “Services”, which include the Website), we appreciate that you are trusting us with your
            personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you
            in the clearest way possible what information we collect, how we use it and what rights you have in relation
            to it. We hope you take some time to read through it carefully, as it is important. If you do not agree with
            the terms outlined in this privacy notice, you may choose to discontinue the use of our Services. For any
            concerns, please contact us at <Link to="/contact">support@thecampstack.com</Link> to discuss your privacy
            preferences.
          </p>
          <p>
            This privacy notice applies to all information collected through our {serviceSectionFromHomepage()} (which,
            as described above, includes our Website), as well as any related services, sales, marketing or events.
          </p>
          <p>
            Please read this privacy notice carefully as it will help you understand what we do with the information
            that we collect.
          </p>
          <p>&nbsp;</p>
          <p className="bold">1. PERSONAL INFORMATION YOU DISCLOSE TO US</p>
          <p>
            <i>In Short:&nbsp; We collect information that you provide to us.</i>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us when you register on the Website, express
            an interest in obtaining information about us or our products and Services, when you participate in
            activities on the Website (such as by posting messages in our online forums or entering competitions,
            contests or giveaways) or otherwise when you contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of your interactions with us and the
            Website, the choices you make and the products and features you use. The personal information we collect may
            include the following:
          </p>
          <p>
            Personal Information Provided by You. We collect names; phone numbers; email addresses; usernames;
            passwords; billing addresses; and other similar information.
          </p>
          <p>
            Payment Data. We may collect data necessary to process your payment if you make purchases, such as your
            payment instrument number (such as a credit card number), and the security code associated with your payment
            instrument.
          </p>
          <p>
            Social Media Login Data. We may provide you with the option to register with us using your existing social
            media account details, like your Facebook, Twitter or other social media account. If you choose to register
            in this way, we will collect the information described in the section called “HOW DO WE HANDLE YOUR SOCIAL
            LOGINS” below.
          </p>
          <p>
            You are responsible for ensuring that the personal information you provide is complete and accurate. If any
            of your information is incorrect or needs updating, you may update it through your account settings or
            contact us for assistance.
          </p>
          <p>&nbsp;</p>
          <p className="bold">Information automatically collected</p>
          <p>
            <i>
              In Short:&nbsp; Some information — such as your Internet Protocol (IP) address and/or browser and device
              characteristics — is collected automatically when you visit our website.
            </i>
          </p>
          <p>
            We automatically collect certain information when you visit, use or navigate the&nbsp;Website. This
            information does not reveal your specific identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and device characteristics, operating system,
            language preferences, referring URLs, device name, country, location, information about who and when you use
            our&nbsp;Website&nbsp;and other technical information. This information is primarily needed to maintain the
            security and operation of our&nbsp;Website, and for our internal analytics and reporting purposes.
          </p>
          <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
          <p>The information we collect includes:</p>
          <ul>
            <li>
              <i>Log and Usage Data.</i>&nbsp;Log and usage data is service-related, diagnostic usage and performance
              information our servers automatically collect when you access or use our Website and which we record in
              log files. Depending on how you interact with us, this log data may include your IP address, device
              information, browser type and settings and information about your activity in the Website (such as the
              date/time stamps associated with your usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as system activity, error reports
              (sometimes called ‘crash dumps’) and hardware settings).
            </li>
          </ul>
          <ul>
            <li>
              <i>Device Data.&nbsp;</i>We collect device data such as information about your computer, phone, tablet or
              other device you use to access the&nbsp;Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server), device application identification numbers,
              location, browser type, hardware model Internet service provider and/or mobile carrier, operating system
              configuration information.
            </li>
          </ul>
          <ul>
            <li>
              <i>Location Data.</i>&nbsp;We collect information data such as information about your device’s location,
              which can be either precise or imprecise. How much information we collect depends on the type of settings
              of the device you use to access the Website. For example, we may use GPS and other technologies to collect
              geolocation data that tells us your current location (based on your IP address). You can opt out of
              allowing us to collect this information either by refusing access to the information or by disabling your
              Locations settings on your device. Note however, if you choose to opt out, you may not be able to use
              certain aspects of the Services.<p>&nbsp;</p>
            </li>
          </ul>
          <p className="bold">2. HOW DO WE USE YOUR INFORMATION?</p>
          <p>
            <i>
              In Short: We process your information to fulfill our contractual obligations to you, comply with legal
              requirements, obtain your consent where necessary, and provide our services effectively.
            </i>
          </p>
          <p>
            We use personal information collected via our Website for a variety of business purposes described below. We
            process your personal information for these purposes in reliance on our legitimate business interests, in
            order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>
              To facilitate account creation and logon process. If you choose to link your account with us to a
              third-party account (such as your Google or Facebook account), we use the information you allowed us to
              collect from those third parties to facilitate account creation and logon process for the performance of
              the contract. See the section below headed “<a>HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>” for further
              information.
            </li>
            <li>
              To post testimonials. We post testimonials on our Website that may contain personal information. Prior to
              posting a testimonial, we will obtain your consent to use your name and the consent of the testimonial. If
              you wish to update, or delete your testimonial, please contact us at support@thecampstack.com and be sure
              to include your name, testimonial location, and contact information.
            </li>
            <li>
              Request feedback. We may use your information to request feedback and to contact you about your use of our
              Website.
            </li>
            <li>
              To enable user-to-user communications. We may use your information in order to enable user-to-user
              communications with each user’s consent.
            </li>
            <li>
              To manage user accounts. We may use your information for the purposes of managing our account and keeping
              it in working order.
            </li>
          </ul>
          <ul>
            <li>
              To send administrative information to you. We may use your personal information to send you product,
              service and new feature information and/or information about changes to our terms, conditions, and
              policies.
            </li>
            <li>
              To protect our Services. We may use your information as part of our efforts to keep our Website safe and
              secure (for example, for fraud monitoring and prevention).
            </li>
            <li>
              To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
              requirements or in connection with our contract.
            </li>
            <li>
              To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may
              need to inspect the data we hold to determine how to respond.
            </li>
          </ul>
          <ul>
            <li>
              Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the Website.
            </li>
            <li>
              Administer prize draws and competitions. We may use your information to administer prize draws and
              competitions when you elect to participate in our competitions.
            </li>
            <li>
              To deliver and facilitate delivery of services to the user. We may use your information to provide you
              with the requested service.
            </li>
            <li>
              To respond to user inquiries/offer support to users. We may use your information to respond to your
              inquiries and solve any potential issues you might have with the use of our Services.
            </li>
          </ul>
          <ul>
            <li>
              To send you marketing and promotional communications. We and/or our third-party marketing partners may use
              the personal information you send to us for our marketing purposes, if this is in accordance with your
              marketing preferences. For example, when expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we will collect personal information from
              you. You can opt-out of our marketing emails at any time (see the “<a>WHAT ARE YOUR PRIVACY RIGHTS</a>”
              below).
            </li>
            <li>
              Deliver targeted advertising to you. We may use your information to develop and display personalized
              content and advertising (and work with third parties who do so) tailored to your interests and/or location
              and to measure its effectiveness.<p>&nbsp;</p>
            </li>
          </ul>
          <p className="bold">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              We only share information with your consent, to comply with laws, to provide you with services, to protect
              your rights, or to fulfill business obligations.
            </i>
          </p>
          <p>We may process or share your data that we hold based on the following legal basis:</p>
          <ul>
            <li>
              Consent: We may process your data if you have given us specific consent to use your personal information
              in a specific purpose.
            </li>
            <li>
              Legitimate Interests: We may process your data when necessary to provide and improve our services, enhance
              user experience, or support our operational needs while ensuring your privacy rights are protected.
            </li>
            <li>
              Performance of a Contract: Where we have entered into a contract with you, we may process your personal
              information to fulfill the terms of our contract.
            </li>
            <li>
              Legal Obligations: We may disclose your information where we are legally required to do so in order to
              comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
              such as in response to a court order or a subpoena (including in response to public authorities to meet
              national security or law enforcement requirements).
            </li>
            <li>
              Vital Interests: We may disclose your information where we believe it is necessary to investigate,
              prevent, or take action regarding potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and illegal activities, or as evidence in
              litigation in which we are involved.<p>&nbsp;</p>
            </li>
          </ul>
          <p>
            More specifically, we may need to process your data or share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              Business Transfers. We may share or transfer your information in connection with, or during negotiations
              of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to
              another company.
            </li>
          </ul>
          <ul>
            <li>
              Affiliates. We may share your information with our affiliates, in which case we will require those
              affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint
              venture partners or other companies that we control or that are under common control with us.
            </li>
          </ul>
          <ul>
            <li>
              Business Partners. We may share your information with our business partners to offer you certain products,
              services or promotions.
            </li>
          </ul>
          <ul>
            <li>
              Other Users. When you share personal information (for example, by posting comments, contributions or other
              content to the Website) or otherwise interact with public areas of the Website, such personal information
              may be viewed by all users and may be publicly made available outside the Website in perpetuity. If you
              interact with other users of our Website and register for our Website through a social network (such as
              Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your
              activity. Similarly, other users will be able to view descriptions of your activity, communicate with you
              within our Website, and view your profile.<p>&nbsp;</p>
            </li>
          </ul>
          <p className="bold">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
          <p>
            <i>In Short:&nbsp;</i>
            <i>We may use cookies and other tracking technologies to collect and store your information.</i>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
            information. Specific information about how we use such technologies and how you can refuse certain cookies
            is set out in our Cookie Notice.<p>&nbsp;</p>
          </p>
          <p className="bold">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              If you choose to register or log in to our services using a social media account, we may have access to
              certain information about you.
            </i>
          </p>
          <p>
            Our Website offers you the ability to register and login using your third-party social media account details
            (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
            information about you from your social media provider. The profile information we receive may vary depending
            on the social media provider but often includes your name, email address, profile picture, and any other
            information you choose to share with us through your social media settings.
          </p>
          <p>
            We will use the information we receive only for the purposes that are described in this privacy notice or
            that are otherwise made clear to you on the relevant Website. Please note that we do not control, and are
            not responsible for, other uses of your personal information by your third-party social media provider. We
            recommend that you review their privacy notice to understand how they collect, use and share your personal
            information, and how you can set your privacy preferences on their sites and apps.
          </p>
          <p>&nbsp;</p>
          <p className="bold">6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law.
            </i>
          </p>
          <p>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this
            privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
            other legal requirements). We will retain your personal information only for as long as necessary to fulfill
            the purposes outlined in this privacy notice, including any legal, accounting, or regulatory obligations
            that may require a longer retention period.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your personal information, we will either delete
            or anonymize such information, or, if this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store your personal information and isolate it
            from any further processing until deletion is possible.
          </p>
          <p>&nbsp;</p>
          <p className="bold">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              We aim to protect your personal information through a system of organizational and technical security
              measures.
            </i>
          </p>
          <p>
            We have implemented appropriate technical and organizational security measures designed to protect the
            security of any personal information we process. However, despite our safeguards and efforts to secure your
            information, no electronic transmission over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
            unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal,
            or modify your information. While we implement security measures to protect your personal information, no
            system is completely secure. We recommend using a secure network when accessing our Website and staying
            vigilant against potential security threats.
          </p>
          <p>&nbsp;</p>
          <p className="bold">8. DO WE COLLECT INFORMATION FROM MINORS?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>We do not knowingly collect data from or market to children under 18 years of age.</i>
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you
            confirm that you are at least 18 years old. We do not permit users under 18 to access our services without
            verified parental consent. If we become aware of any data collected from a minor without proper consent, we
            will take steps to remove such information. If you become aware of any data we may have collected from
            children under age 18, please contact us at support@thecampstack.com.
          </p>
          <p>&nbsp;</p>
          <p className="bold">9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              In some regions, such as the European Economic Area, you have rights that allow you greater access to and
              control over your personal information. You may review or request changes to your personal information
              and, where applicable, request account termination, subject to any legal or contractual obligations.
            </i>
          </p>
          <p>
            In some regions (like the European Economic Area), you have certain rights under applicable data protection
            laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii)
            to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv)
            if applicable, to data portability. In certain circumstances, you may also have the right to object to the
            processing of your personal information. To make such a request, please email support@thecampstack.com. We
            will consider and act upon any request in accordance with applicable data protection laws.
          </p>
          <p>
            If we are relying on your consent to process your personal information, you have the right to withdraw your
            consent at any time. Please note however that this will not affect the lawfulness of the processing before
            its withdrawal, nor will it affect the processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.
          </p>
          <p>
            If you are resident in the European Economic Area and you believe we are unlawfully processing your personal
            information, you also have the right to complain to your local data protection supervisory authority. You
            can find their contact details here:&nbsp;
            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>
          <p>
            If you are resident in Switzerland, the contact details for the data protection authorities are available
            here:&nbsp;
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may email us at support@thecampstack.com.
          </p>
          <p>&nbsp;</p>
          <p className="bold">Account Information</p>
          <p>
            If you would at any time like to review or change the information in your account or terminate your account,
            you can:
          </p>
          <ul>
            <li>Log in to your account settings and update your user account.</li>
            <li>Contact us using the contact information provided.</li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or delete your account from our active
            databases. However, we may retain certain information as required by law, for fraud prevention, to
            troubleshoot issues, assist with investigations, or enforce our Terms of Use and/or comply with applicable
            legal requirements.
          </p>
          <p>
            Cookies and similar technologies:&nbsp;Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
            remove cookies or reject cookies, this could affect certain features or services of our Website. To opt-out
            of interest-based advertising by advertisers on our Website visit&nbsp;
            <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
          </p>
          <p>
            Opting out of email marketing:&nbsp;You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided
            below. You will then be removed from the marketing email list – however, we may still communicate with you,
            for example to send you service-related emails that are necessary for the administration and use of your
            account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
          </p>
          <ul>
            <li>
              Contact us using the contact information provided.<p>&nbsp;</p>
            </li>
          </ul>
          <p className="bold">10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
          <p>
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”)
            feature or setting you can activate to signal your privacy preference not to have data about your online
            browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing
            and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
            or any other mechanism that automatically communicates your choice not to be tracked online. If an industry
            or legal standard for online tracking is established in the future, we will update our privacy notice
            accordingly and comply with applicable requirements.<p>&nbsp;</p>
          </p>
          <p className="bold">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>
              Yes, if you are a resident of California, you are granted specific rights regarding access to your
              personal information.
            </i>
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
            California residents to request and obtain from us, once a year and free of charge, information about
            categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
            the names and addresses of all third parties with which we shared personal information in the immediately
            preceding calendar year. If you are a California resident and would like to make such a request, please
            submit your request in writing to us using the contact information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a registered account with the Website, you
            have the right to request removal of unwanted data that you publicly post on the Website. To request removal
            of such data, please contact us using the contact information provided below, and include the email address
            associated with your account and a statement that you reside in California. We will take reasonable steps to
            remove the requested data from public display on the Website. However, please note that we may retain
            certain information as required by law, for legitimate business purposes, or to comply with legal
            obligations.<p>&nbsp;</p>
          </p>
          <p className="bold">12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
          <p>
            <i>In Short:&nbsp;</i>&nbsp;
            <i>Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated version will be indicated by an updated
            “Revised” date and the updated version will be effective as soon as it is accessible. If we make material
            changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or
            by directly sending you a notification. We encourage you to review this privacy notice periodically. If we
            make material changes, we will take appropriate measures to inform you, consistent with the significance of
            the changes and applicable legal requirements.<p>&nbsp;</p>
          </p>
          <p className="bold">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
          <p>
            If you have questions or comments about this notice, you may email us at{" "}
            <Link to="/contact">support@thecampstack.com</Link>.<p>&nbsp;</p>
          </p>
          <p className="bold">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
          <p>
            Based on the applicable laws of your country, you may have the right to request access to the personal
            information we collect from you, change that information, or delete it in some circumstances. To request to
            review, update, or delete your personal information, please submit a request form by emailing
            <Link to="/contact">support@thecampstack.com</Link>. We will respond to your request within 30 days.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
